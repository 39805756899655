import {NgModule} from '@angular/core';


import {ThemeModule} from '../../@theme/theme.module';
import {LoginComponent} from './login.component';
import {LoginRoutingModule} from './login-routing.module';
import {
  NbLayoutModule,
} from '@nebular/theme';
import {LoginService} from './login.service';
import {NgxLoadingModule} from 'ngx-loading';
import {RegistrationComponent} from '../registration/registration.component';
import {ArchwizardModule} from 'ng2-archwizard';
import {SimpleNotificationsModule} from 'angular2-notifications';
import {ForgotPasswordComponent} from '../forgot-password/forgot-password.component';

@NgModule({
  imports: [
    NbLayoutModule,
    LoginRoutingModule,
    ThemeModule,
    NgxLoadingModule.forRoot({}),
    ArchwizardModule,
    SimpleNotificationsModule.forRoot(),
  ],
  declarations: [
    LoginComponent,
    RegistrationComponent,
    ForgotPasswordComponent,
  ],
  providers: [LoginService],
})
export class LoginModule {
}
