import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Injectable()
export class ShareService {
	constructor(private _datePipe: DatePipe) {
	}

	// @ts-ignore
	public loadScript(url) {
		const node = document.createElement('script');
		node.src = url;
		node.type = 'text/javascript';
		node.async = true;
		node.charset = 'utf-8';
		document.getElementsByTagName('head')[0].appendChild(node);
	}

	public getUserId() {
		const data = JSON.parse(localStorage.getItem('data'));
		return data['u_id'];
	}

	public getUserName() {
		const data = JSON.parse(localStorage.getItem('data'));
		return data['owner_name'];
	}

	public getUserRole() {
		const data = JSON.parse(localStorage.getItem('data'));
		return data['role_type'];
	}

	public getCommission() {
		const data = JSON.parse(localStorage.getItem('data'));
		return data['commission'];
	}

	public getAgentCommissionForRetailer() {
		const data = JSON.parse(localStorage.getItem('data'));
		return data['agent_com'];
	}

	public getUserData() {
		return JSON.parse(localStorage.getItem('data'));
	}

	public changeDateFormatYYYYMMDD(date) {
		return this._datePipe.transform(new Date(date), 'yyyy-MM-dd');
	}

	public getFirstDateOfMonth() {
		const date = new Date();
		return this.changeDateFormatYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 1));
	}

	public getLastDateOfMonth() {
		const date = new Date();
		return this.changeDateFormatYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 0));
	}

	public getAfterDateFromTodayDate(numberDay) {
		const someDate = new Date();
		const numberOfDaysToAdd = numberDay;
		someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
		return someDate;
	}


	public calculateDays(from, to) {

		const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
		// const firstDate = new Date(2008, 1, 1);
		// const secondDate = new Date(2008, 1, 22);

		const firstDate = from;
		const secondDate = to;

		const result = Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / (oneDay)));
		return result + 1;
	}

	public getMothFirstDate() {
		const date = new Date();
		return new Date(date.getFullYear(), date.getMonth(), 1);
	}

	print(printContents: any): void {
		let popupWin;
		printContents = document.getElementById('print').innerHTML;
		popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
		popupWin.document.open();
		popupWin.document.write(`
      <html>
        <head>
          <title> Invoice - Bronco</title>
         <link rel="stylesheet"
          href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"  crossorigin="anonymous"></link>
			<style>
			.hidePrintRow{
					display: block;
			}
			.blockSection{
				padding-right: 0;
			}
			.col-md-4.col-sm-6.col-xs-9.firstBlock {
				padding-right: 0;
			}
			.col-md-4.col-sm-6.col-xs-9.secondBlock {
				padding-right: 0;
			}
			.col-md-4.col-sm-6.col-xs-9.thirdBlock {
				padding-right: 0;
			}
			.col-md-12.orderGridBlock {
				padding: 0;
			}			
	.rootFooter{
			position: relative; left: 0; bottom: 0; width: 100%; text-align: center;
	}
			</style>
										</head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
		);
		popupWin.document.close();
	}

	printInvoice(printContents: any, orderNumber: any): void {
		let popupWin;
		printContents = document.getElementById('print').innerHTML;
		popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
		popupWin.document.open();
		popupWin.document.write(`
      <html>
        <head>
         <title> Order_No_${orderNumber}_Bronco </title>
         <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"  crossorigin="anonymous"></link>
									<style>
										@page { margin: 5px 25px; size:A4; }

											html {
												position: relative;
												min-height: 100%;
											}
										 
											body {
												margin-bottom: 50px;
											}

										.invoiceFooter {
											position: absolute;
											bottom: 0;
											width: 100%;
											height: 50px;
											text-align: center;
										}

										.invoiceFooterAlign {
											width: 100%;
										}

										.hidePrintRow{
												display: block;
										}
										.blockSection{
											padding-right: 0;
										}
										.col-md-4.col-sm-6.col-xs-9.firstBlock {
											padding-right: 0;
										}
										.col-md-4.col-sm-6.col-xs-9.secondBlock {
											padding-right: 0;
										}
										.col-md-4.col-sm-6.col-xs-9.thirdBlock {
											padding-right: 0;
										}
										.col-md-12.orderGridBlock {
											padding: 0;
										}			

										.rootPrintDiv .tableHead {
											background-color: #e6e6e6 !important;
											margin: -3px !important;
											padding: 3px !important;
											margin-bottom: 5px !important;
											font-weight: bold;
										}
								
										.rootPrintDiv .tableHead-2 {
											background-color: #e6e6e6 !important;
											margin: -3px !important;
											padding: 3px !important;
											font-weight: bold;
										}
							
										.rootPrintDiv .table {
											width: 100%;
											max-width: 100%;
											margin-bottom: 8px;
											font-size: 14px;
										}
								
										.rootPrintDiv .font-weight-bold {
											font-weight: bold;
										}
								
										.rootPrintDiv .table>tbody>tr>td,
										.table>tbody>tr>th,
										.table>tfoot>tr>td,
										.table>tfoot>tr>th,
										.table>thead>tr>td,
										.table>thead>tr>th {
											padding: 3px;
										}
										
										.rootPrintDiv table.table.table-bordered.bank-details-table {
											padding-top: 20px;
											font-size: 12px;
										}
								</style>
							</head>
    			<body onload="window.print();window.close()">${printContents}</body>
      </html>`
		);
		popupWin.document.close();
	}

}
