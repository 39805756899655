import {Component, Input, OnInit} from '@angular/core';

import {NbMenuService, NbSidebarService} from '@nebular/theme';
import {UserData} from '../../../@core/data/users';
import {AnalyticsService} from '../../../@core/utils';
import {Constant} from '../../../share/constant';
import {Router} from '@angular/router';
import {ShareService} from '../../../share/share.service';

const googleTranslateAPiUrl = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';


@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  @Input() position = 'normal';

  user: any;

  userMenu = [{title: 'Log out'}, {title: 'Change Password'}];

  data = JSON.parse(localStorage.getItem('data'));

  userName = '';


  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private userService: UserData,
              private router: Router,
              private _shareService: ShareService,
              private analyticsService: AnalyticsService) {

    this.userName = this._shareService.getUserName();
    this.menuService.onItemClick().subscribe(event => {
      this.onContextItemSelection(event.item.title);
    });
  }

  ngOnInit() {
    this._shareService.loadScript(googleTranslateAPiUrl);
    this.userService.getUsers()
      .subscribe((users: any) => this.user = users.nick);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }

  onContextItemSelection(title) {
    if (title === 'Log out') {
      localStorage.clear();
      this.router.navigate([Constant.routePage.login]);
      // window.location.reload();
    } else if (title === 'Change Password') {
      console.log('click', title);
      this.router.navigate([Constant.routePage.changePassword]);
    }
  }
}
