import {Injectable} from '@angular/core';
import {NotificationsService} from 'angular2-notifications';

@Injectable()
export class GlobalNotification {
  constructor(private _notify: NotificationsService) {
  }

  /*
   * This function is used to popup success notification
   * */
  onSuccess(title: string, content: string) {
    this._notify.success(title, content, {
      timeOut: 2000,
      showProgressBar: false,
      pauseOnHover: false,
      clickToClose: true,
      maxLength: 400,
    });
  }

  /*
   * This function is used to popup error notification
   * */
  onError(title: string, content: string) {
    this._notify.error(title, content, {
      timeOut: 4000,
      showProgressBar: false,
      pauseOnHover: false,
      clickToClose: true,
      maxLength: 400,
    });
  }

  /*
   * This function is used to popup informatyion or loading notification
   * */
  onInfo(title: string, content: string) {
    this._notify.info(title, content, {
      showProgressBar: true,
      pauseOnHover: false,
      clickToClose: false,
      maxLength: 400,
    });
  }

  /*
   * This function is used to remove popup notification
   * */
  remove() {
    this._notify.remove();
  }

}
