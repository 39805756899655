import {Component, TemplateRef, ViewChild} from '@angular/core';
import {Router, NavigationExtras} from '@angular/router';
import {
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import {LoginService} from '../login/login.service';

import {ngxLoadingAnimationTypes} from 'ngx-loading';
import {Constant} from '../../share/constant';
import {ShareService} from '../../share/share.service';
import {WizardComponent} from 'ng2-archwizard/dist/components/wizard.component';
import * as $ from 'jquery';

const PrimaryWhite = '#E91E63';
const SecondaryGrey = '#673AB7';

@Component({
  selector: 'ngx-registration',
  styleUrls: ['../login/login.component.scss'],
  templateUrl: './registration.component.html',
})
export class RegistrationComponent {
  @ViewChild(WizardComponent) wizardComponent: WizardComponent;
  // declaration for Form
  loginForm: FormGroup;
  otpForm: FormGroup;
  isLogin = true;
  isOtp = false;
  errorMsg = '';
  error = {
    // email: '',   Removed by alok to remove duplicate check for email & contact
    // contact: '', Removed by alok to remove duplicate check for email & contact
    password_match: '',
    otp: '',
  };
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = false;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;


  loadAPI: Promise<any>;
  registerForm: FormGroup;
  storeForm: FormGroup;
  contactForm: FormGroup;
  bankVatForm: FormGroup;
  passwordForm: FormGroup;
  isEmailContactValid: boolean = true; // make it true by alok to remove duplicate check for email & contact
  emailContactNextButton = 'Validate';
  countries;
  state;
  city;

  constructor(private _router: Router,
              private _formBuilder: FormBuilder,
              private  _loginService: LoginService,
              private _shareService: ShareService,
  ) {

    // login form
    this.loginForm = this._formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });

    this.storeForm = this._formBuilder.group({
      role_type: '1',
      company_name: ['', [Validators.required]],
      store_name: [''],
      country: ['', [Validators.required]],
      state: [''],
      city: ['', [Validators.required]],
      zip_code: ['', [Validators.required]],
      street: ['', [Validators.required]],
    });

    this.contactForm = this._formBuilder.group({
      owner_name: ['', [Validators.required]],
      contact_person: ['', [Validators.required]],
      person_position: [''],
      // fax: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      shipping_address: ['', [Validators.required]],
    });

    this.bankVatForm = this._formBuilder.group({
      udid_vat: ['', [Validators.required]],
      bank_name: [''],
      bank_address: [''],
      current_account_number: [''],
    });

    this.passwordForm = this._formBuilder.group({
      password: ['', [Validators.required]],
      confirm_password: ['', [Validators.required]],
      otp: [''],
      commission: [''],
    });


    // otp form
    this.otpForm = this._formBuilder.group({
      otp: ['', [Validators.required]],
    });

    console.log('contructor');
    // this._shareService.loadScript(ajaxGoogleAPiUrl);
    // this._shareService.loadScript(geoDataUrl);
    this.getCounty();
    // this.wizardComponent.navigation
  }


  gotoDashboard() {
    this._router.navigate(
      ['pages/dashboard']);
  }

  toggle() {
    this.isLogin = !this.isLogin;
    if (!this.isLogin) {
      // this._shareService.loadScript(ajaxGoogleAPiUrl);
      // this._shareService.loadScript(geoDataUrl);
    }
  }

  finishFunction() {
    if (this.matchPassword()) {
      this.storeForm.patchValue({
        country: this.countries[this.storeForm.value['country']]['name'],
        // city: this.city[this.storeForm.value['city']]['name'],
      });
      const data = $.extend(this.storeForm.value, this.contactForm.value);
      const data1 = $.extend(data, this.bankVatForm.value);
      // this.passwordForm.patchValue({'otp': this.otpForm.value['otp']});
      const data2 = $.extend(data1, this.passwordForm.value);
      console.log(data2);
      this.loading = true;

      this._loginService.register(data2).subscribe(res => {
          console.log('res');
          console.log(res);
          this.loading = false;

          if (res['status']) {
            this.isLogin = true;
            this.isOtp = true;
          } else {
            this.error['otp'] = 'Something is wrong.';
          }
        },
        err => {
          this.loading = false;
          console.log('error');
          console.log(err);
        });
    } else {
      this.error['password_match'] = 'password not match';
    }
  }


  gotoLogin() {
    this._router.navigate(
      [Constant.routePage.login]);
  }
  gotoBroncoWebsite() {
			window.location.href = "https://broncoequestrian.com/pages/explore-bronco"
  }


  verifyOtp() {
    this.passwordForm.patchValue({'otp': this.otpForm.value['otp']});
    console.log(JSON.stringify(this.registerForm.value));
    this.loading = true;

    this._loginService.register(this.registerForm.value).subscribe(res => {
        console.log('res');
        console.log(res);
        this.loading = false;

        if (res['status']) {
          this.isLogin = true;
          this.isOtp = false;
          this._router.navigate(
            [Constant.routePage.login]);
        }
      },
      err => {
        console.log('error');
        console.log(err);
      });

  }

  validateEmailAndContact() {
    this.error['email'] = '';
    this.error['contact'] = '';
    this.loading = true;
    this._loginService.validateUser({
      email: this.contactForm.value['email'],
      contact: this.contactForm.value['phone'],
    }).subscribe(res => {
        this.loading = false;

        console.log('res');
        console.log(res);
        if (!res['status']) {
          this.error['email'] = res['data']['email'];
          this.error['contact'] = res['data']['contact'];
        } else {
          this.isEmailContactValid = true;
          this.emailContactNextButton = 'Next';
        }
      },
      err => {
        console.log('error');
        console.log(err);
      });
  }

  matchPassword() {
    let result = false;
    if (this.passwordForm.value['password'] === this.passwordForm.value['confirm_password']) {
      result = true;
    }
    return result;
  }

  getCounty() {
    this.loading = true;
    this._loginService.getCountry().subscribe(res => {
        console.log('res');
        console.log(res);
        this.loading = false;
        this.countries = res['results'];
      },
      err => {
        console.log('error');
        console.log(err);
      });
  }

  getState(id) {
    const data = {
      id: id,
    };
    this.loading = true;
    this._loginService.getState(data).subscribe(res => {
        console.log('res');
        console.log(res);
        this.loading = false;
        this.state = res['results'];
      },
      err => {
        console.log('error');
        console.log(err);
      });
  }

  getCity(id) {
    const data = {
      id: id,
    };
    this.loading = true;
    this._loginService.getCity(data).subscribe(res => {
        console.log('res');
        console.log(res);
        this.loading = false;
        this.city = res['results'];
      },
      err => {
        console.log('error');
        console.log(err);
      });
  }


  onSelectCountry(index) {
			console.log(index);
    // this.storeForm.patchValue({
    //   state: '',
    //   city: '',
    // });
    // this.getCity(this.countries[index]['id']);
    // this.getState(this.countries[index]['id']);
  }

  onSelectState(index) {
    this.storeForm.patchValue({
      city: '',
    });
    // this.getCity(this.state[index]['id']);
  }
}
