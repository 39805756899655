import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {environment} from '../../../environments/environment';
import {Constant} from '../../share/constant';

@Injectable()
export class LoginService {
  constructor(private _httpClient: HttpClient) {

  }

  // login service.
  login(userCredential) {
    return this._httpClient.post(`${environment.serviceBaseURL}${Constant.url.login}`, userCredential);
  }

  // registration service.
  register(param) {
    return this._httpClient.post(`${environment.serviceBaseURL}${Constant.url.register}`, param);
  }

  addOtp(param) {
    return this._httpClient.post(`${environment.serviceBaseURL}${Constant.url.add_otp}`, param);
  }

  validateUser(param) {
    return this._httpClient.post(`${environment.serviceBaseURL}${Constant.url.user_validate}`, param);
  }

  checkUsername(param) {
    return this._httpClient.post(`${environment.serviceBaseURL}${Constant.url.checkUsername}`, param);
  }

  forgotPassword(param) {
    return this._httpClient.post(`${environment.serviceBaseURL}${Constant.url.forgot_password}`, param);
  }

  changePassword(param) {
    return this._httpClient.post(`${environment.serviceBaseURL}${Constant.url.change_password}`, param);
  }

  getCountry() {
    return this._httpClient.get(`${environment.serviceBaseURL}${Constant.url.country}`);
  }

  getState(param) {
    return this._httpClient.post(`${environment.serviceBaseURL}${Constant.url.state}`, param);
  }

  getCity(param) {
    return this._httpClient.post(`${environment.serviceBaseURL}${Constant.url.city}`, param);
  }

}
