import {ngxLoadingAnimationTypes} from 'ngx-loading';

export class Constant {

  static loadingConfig = {
    animationType: ngxLoadingAnimationTypes.doubleBounce,
    primaryColour: '#E91E63',
    secondaryColour: '#673AB7',
    backdropBorderRadius: '3px',
  };

  static url = {
    login: 								'login',
    register: 					'user/add',
    updateUser: 			'user/update',
				deleteUser: 			'user/delete_retailer',
				deleteStaff: 		'user/delete_staff',
				deleteAgent: 		'user/delete_agent',
    add_otp: 						'otp/add',
    user_validate: 'user/validate',

    product: 'product/all',
    color_all: 'color/all',
    size_all: 'size/all',
    order_place: 'order/add',
    user_order_list: 'order/user-order/all',
    order_list_item: 'order/user-item',
    order_claim: 'order/claim',
    order_all: 'order/all',
				order_30_days: 'order/order_30_days',
				updateRecivedAmounts: 'order/updateRecivedAmounts',
				updateDiscountedAmount: 'order/updateDiscountedAmount',
    update_order_status: 'order-status/update',
    update_claim_status: 'claim/update-status',
    add_claim: 'claim/add',
    claim_list: 'claim/all',
    claim_details: 'claim/details',
    color_by_product_id: 'product/color',
    size_by_color_id: 'product/size',
    product_name: 'product/product-name/add',
    product_color: 'product/product-color/add',
    product_size: 'product/product-size/add',
    product_color_combination: 'product/product-color-combination/add',
    product_group: 'product/product-group/add',
    product_group_details: 'product/details',
    update_product_group: 'product/update',
    product_name_with_color: 'product-with-color/all',
    add_product_group: 'product-group/add',
    product_group_list: 'product-group/all',
				deleteProducts: 'product/delete',
    retailer_list: 'retailer/all',
    agent_retailer_list: 'agent-retailer/all',
    retailer_details: 'retailer/details',
    agent_details: 'agent/details',
				staff_details: 'staff/details',
    update_status: 'agent/update-status',
    retailer_approved: 'retailer/approved',
    update_retailer_agent: 'retailer/update-agent',
    add_agent: '',
    agent_list: 'agent/all',
    staff_list: 'staff/all',
    checkUsername: 'check-username',
    forgot_password: 'forgot-password',
    change_password: 'change-password',
    dashboard_data: 'dashboard',
    dashboard_claim: 'dashboard/claim',
    cancel_order: 'order-status/cancel',
    order_payment_received: 'order-status/payment-received',
    city: 'city',
    state: 'state',
    country: 'country',
    validate_agent_location: 'user/validate_agent',
    order_commission: 'order/commission',
    product_name_list: 'product/name-all',
    product_code: 'product/product-code',
    agent_commission: 'user/commission',
    retailer_update: 'retailer/update',
  };


  static routePage = {
    root: '/',
    dashboard: '/pages/dashboard',
    login: '/login',
    registration: '/registration',
    orderList: '/pages/order/order-history',
    claimList: '/pages/order/claim-order-list',
    orderDetails: '/pages/order/order-details',
    orderClaim: '/pages/order/order-claim',
				retailerList: '/pages/retailer/retailer-list',
    retailerDetails: '/pages/retailer/retailer-details',
    userOrderDetails: '/pages/order/user-order-details',
    claimOrderDetails: '/pages/order/claim-order-details',
    agentDetails: '/pages/agent/agent-details',
    editAgent: '/pages/agent/edit-agent',
				editStaff: '/pages/staff/edit-staff',
    agentList: '/pages/agent/agent-list',
				staffList: '/pages/staff/staff-list',
    forgotPassword: '/forgot-password',
    changePassword: '/pages/change-password',
    retailerUpdate: '/pages/retailer/retailer-update',
    reOrder: '/pages/order/re-order',
    updateProductGroup: '/pages/product/update-product-group',
    productList: 'pages/product/product-list',
  };

  static notificationTitle = {
    invalidUrl: 'Invalid Url',
    serverError: 'Connection Failed',
    login_error: 'Login Error',
    error: 'Error',
    success: 'Success',
    update: 'Update',
    loading: 'Loading...',
  };

  static notificationMsg = {
    success: 'Add Record Successfully',
    update_status: 'Update Status Successfully',
    approved_status: 'Approved Status Successfully',
    update: 'Update Successfully',
    cart: 'Please Add Product',
    order_pair: 'Please Add Order Pair',
    deleteSuccess: 'Delete Record Successfully',
    error: 'add Record Failed',
    load: 'Please Wait',
    validDate: 'Selected date must not be greater than 31',
  };
}
