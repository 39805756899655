import {CanActivate, Router, CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Injector, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Constant} from "./constant";

// import {SharedService} from '../shared.services';

@Injectable()
export class AuthGuardService implements CanLoad, CanActivate {
  constructor(private router: Router) {
  }

  canLoad(router): boolean | Observable<boolean> | Promise<boolean> {
    let isAuthenticatedWithAllConfiguredFeatures = false;
    console.log('can load');
    if (localStorage.getItem('token')) {
      isAuthenticatedWithAllConfiguredFeatures = true;
    } else {
      // this.sharedService.error('Please login', '');
      this.goToLogin();
    }

    return isAuthenticatedWithAllConfiguredFeatures;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let isAuthenticatedWithAllConfiguredFeatures = false;
    if (localStorage.getItem('token')) {
      isAuthenticatedWithAllConfiguredFeatures = true;
    } else {
      // this.sharedService.error('Please login', '');
      this.goToLogin();
    }

    return isAuthenticatedWithAllConfiguredFeatures;
  }

  goToLogin(): boolean {
    this.router.navigateByUrl(Constant.routePage.login);
    return false;
  }
}
