import {Component, TemplateRef, ViewChild} from '@angular/core';
import {Router, NavigationExtras} from '@angular/router';
import {
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import {LoginService} from './login.service';

import {ngxLoadingAnimationTypes} from 'ngx-loading';
import {Constant} from '../../share/constant';
import {ShareService} from '../../share/share.service';

const PrimaryWhite = '#E91E63';
const SecondaryGrey = '#673AB7';

const geoDataUrl = '//geodata.solutions/includes/countrystatecity.js';
const ajaxGoogleAPiUrl = '//ajax.googleapis.com/ajax/libs/jquery/1.11.1/jquery.min.js';
import {GlobalNotification} from '../../share/global.notification';


@Component({
  selector: 'ngx-login',
  styleUrls: ['./login.component.scss'],
  templateUrl: './login.component.html',
  providers: [GlobalNotification],
})
export class LoginComponent {

  // declaration for Form
  loginForm: FormGroup;
  registerForm: FormGroup;
  otpForm: FormGroup;
  isLogin = true;
  isOtp = false;
  errorMsg = '';
  error = {
    email: '',
    contact: '',
  };
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = false;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;

  constructor(private _router: Router,
              private _formBuilder: FormBuilder,
              private  _loginService: LoginService,
              private _shareService: ShareService,
              private _globalNotification: GlobalNotification,
  ) {

    // login form
    this.loginForm = this._formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
    this._shareService.loadScript(ajaxGoogleAPiUrl);
    this._shareService.loadScript(geoDataUrl);
  }


  gotoDashboard() {
    this._router.navigate(
      ['pages/dashboard']);
  }

  toggle() {
    this._router.navigate(
      [Constant.routePage.registration]);
  }


  login() {
    this.errorMsg = '';
    // @ts-ignore
    console.log(this.loginForm.value);
    this.loading = true;
    this._loginService.login(this.loginForm.value).subscribe(res => {
        console.log('res');
        console.log(res);
        this.loading = false;
        if (!res['status']) {
          this.errorMsg = res['msg'];
        } else {
          localStorage.setItem('token', res['token']);
          localStorage.setItem('data', JSON.stringify(res['data']));
          this._router.navigate(
            [Constant.routePage.dashboard]);
        }
      },
      err => {
        this.loading = false;
        this.errorMsg = 'Something Wrong Please try again';
        console.log('error');
        console.log(err);
      });
  }

  goToForgotPassword() {
    this._router.navigate(
      [Constant.routePage.forgotPassword]);
  }

}
