import {Component, TemplateRef, ViewChild} from '@angular/core';
import {Router, NavigationExtras} from '@angular/router';
import {
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import {LoginService} from '../login/login.service';

import {ngxLoadingAnimationTypes} from 'ngx-loading';
import {Constant} from '../../share/constant';
import {ShareService} from '../../share/share.service';

const PrimaryWhite = '#E91E63';
const SecondaryGrey = '#673AB7';

const geoDataUrl = '//geodata.solutions/includes/countrystatecity.js';
const ajaxGoogleAPiUrl = '//ajax.googleapis.com/ajax/libs/jquery/1.11.1/jquery.min.js';
import {GlobalNotification} from '../../share/global.notification';


@Component({
  selector: 'ngx-forgot-password',
  styleUrls: ['../login/login.component.scss'],
  templateUrl: './forgot-password.component.html',
  providers: [GlobalNotification],
})
export class ForgotPasswordComponent {

  // declaration for Form
  loginForm: FormGroup;
  changePasswordForm: FormGroup;
  registerForm: FormGroup;
  otpForm: FormGroup;
  isEmailVerify = false;

  isOtp = false;
  errorMsg = '';
  error = {
    email: '',
    contact: '',
  };
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = false;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  isPasswordMatch = '';
  isPasswordUpdate = false;

  constructor(private _router: Router,
              private _formBuilder: FormBuilder,
              private  _loginService: LoginService,
              private _shareService: ShareService,
              private _globalNotification: GlobalNotification,
  ) {

    // login form
    this.loginForm = this._formBuilder.group({
      email: ['', [Validators.required]],
    }); // login form
    this.changePasswordForm = this._formBuilder.group({
      otp: ['', [Validators.required]],
      email: '',
      new_pass: ['', [Validators.required]],
      conform_password: ['', [Validators.required]],
    });
  }


  gotoLogin() {
    this._router.navigate(
      [Constant.routePage.login]);
  }

  checkEmail() {
    this.errorMsg = '';
    console.log(this.loginForm.value);
    this.loading = true;
    this._loginService.checkUsername(this.loginForm.value).subscribe(res => {
        this.loading = false;
        console.log('res');
        console.log(res);
        if (res['status']) {
          this.isEmailVerify = true;
        } else {
          this.errorMsg = 'Email not valid';
        }
      },
      err => {
        this.loading = false;
        this.errorMsg = 'Something Wrong Please try again';
        console.log('error');
        console.log(err);
      });

  }


  changePassword() {
    this.errorMsg = '';
    this.changePasswordForm.patchValue({
      email: this.loginForm.value['email'],
    });
    console.log(this.changePasswordForm.value);
    if (this.changePasswordForm.value['new_pass'] === this.changePasswordForm.value['conform_password']) {
      this.loading = true;
      this._loginService.forgotPassword(this.changePasswordForm.value).subscribe(res => {
          this.loading = false;
          if (res['status']) {
            this.isPasswordUpdate = true;
          } else {
            this.errorMsg = res['message'];
          }
        },
        err => {
          this.loading = false;
          this.errorMsg = 'Something Wrong Please try again';
        });
    } else {
      this.isPasswordMatch = 'New and Confirm Password not Match';
    }
  }
}
