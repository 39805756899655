import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';

import {LoginComponent} from '../login/login.component';
import {RegistrationComponent} from '../registration/registration.component';
import {ForgotPasswordComponent} from '../forgot-password/forgot-password.component';

const routes: Routes = [{
  path: '',
  component: LoginComponent,
}, {
  path: 'registration',
  component: RegistrationComponent,
}, {
  path: 'forgot-password',
  component: ForgotPasswordComponent,
},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LoginRoutingModule {
}
